<template>
  <div class="box">
    <div class="banner-box">
      <img src="@/img/guibi/tag1.png" class="xbanner" />
      <div class="text">
        企业专属信用报告整合涉税经营信息、工商、司法、知
        识产权、企业主等多维度信息，进行交叉验证，同时基
        于大数据分析、专家评分模型输出企业信用评级及预授
        信额度，主要用于辅助金融机构直观判断企业风险，可 有效提高决策效率。<br />
        此外，企业专属信用报告可在政府采购、招标投标、行
        政审批、市场准入、资质审核等事项中，充分发挥对企 业信用评估的作用。
      </div>
    </div>
    <div class="button" @click="show = true">生成专属信用报告</div>
    <van-dialog
      v-model="show"
      confirmButtonText="知道了"
      confirmButtonColor="#0084ff"
    >
      <div class="alert">
        <img src="@/img/guibi/alert.png" class="" />
        <div class="hint">功能建设中，敬请期待…</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  min-height: calc(100vh - 46px);
  padding-top: 46px;
  background: #fafafa;
  .banner-box {
    width: 100%;
    background: #ffffff;
    text-align: center;
    padding-bottom: 14px;
    .xbanner {
      width: 315px;
      height: 130px;
      margin-top: 20px;
    }
    .text {
      width: 312px;
      margin: 0 auto;
      margin-top: 9px;
      color: #666666;
      font-size: 13px;
      text-align: left;
      line-height: 20px;
    }
  }
  .button {
    width: 220px;
    height: 42px;
    background: #0084ff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    line-height: 42px;
    margin: 0 auto;
    margin-top: 60px;
  }
  .alert {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    img {
      width: 140px;
      height: 110px;
    }
    .hint {
      font-size: 14px;
      color: #333333;
    }
  }
}
</style>
